.fio-partner {
  picture {
    img {
      width: 200px;
      height: auto;

      &.cgx {
        width: auto;
      }
    }

    &.foundries {
      img {
        width: 80px;
      }
    }
  }
}
